
const NotImplemented = () => {
    return (
        <>
            <h1>Feature not ready yet ¯\_(ツ)_/¯</h1>
            <p>We haven't finished this feature yet, stay tuned and check back some time later.</p>
        </>
    )
};

export default NotImplemented;
